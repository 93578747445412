<template>
  <div class="pay-container">
    <div class="main">
      <div class="info">
        <div class="vip-name" :class="type">{{ name }}</div>
        <div class="vip-info" :class="type">
          <div class="name">{{ childName }}会员</div>
          <div class="price">
            <span>{{ price }}</span
            >元
          </div>
        </div>
      </div>

      <div class="divider"></div>
      <div class="intro" v-html="intro"></div>
    </div>

    <div class="bottom">
      <div class="price">
        应付：<a :class="type"
          ><span>{{ price }}</span
          >元</a
        >
      </div>
      <div class="right">
        <div class="btn back" :class="type" @click="goback(false)">再想想</div>
        <div class="btn" :class="type" @click="pay">确认支付</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pay",
  components: {},
  data() {
    return {
      type: "",
      name: "",
      childName: "",
      price: "",
      childId: "",

      vipIntro: {
        redvip:
          "1. 红云会员不具备神奇功能，无法包罗万象，不可能包含任何文章范文。</br>" +
          "2. 请先行搜索，看是否能够找到想要资料后再决定下单。</br>" +
          "3.虚拟物品一经下单，不提供退款。</br>",
        goldvip:
          "1.购买前请先试学再决定下单。</br>" +
          "2.课程会员到期后将无法观看课程。</br>" +
          "3.课程一旦开通无法暂停延期，请合理安排学习时间。</br>" +
          "4.会员只能观看已经上架的课程。不能以某课程还未更新完毕而要求延长会员时间。</br>" +
          "5.会员无法获得【王牌训练】系列课程电子版资料、纸质版资料，也无法单独购买。只能观看【王牌训练】系列课程的录播视频。</br>" +
          "6.不提供任何涉及课程问题的人工答疑和指导。",
        bluevip:
          "请先行试用软件功能，看是否符合需要再决定下单。虚拟物品一经下单，不提供退款。",
        greenvip: 
          "请先行试用软件功能，看是否符合需要再决定下单。虚拟物品一经下单，不提供退款。</br>" +
          "请注意：</br>" +
          "本套训练课程项目从2022年1月才正式开始启动，并且作为知行写作今后的重磅项目，我们会将绝大部分精力全部倾力于此。</br>" +
          "因为体量非常大，所以全套课程全部结束，预计可能会历经1年甚至永久持续更新变化。更新频率为2天一次。</br>" +
          "如果你对此有所介意，请慎重考虑购买。",
      },

      intro: "",

      order_id: "",
    };
  },

  created() {
    /** 测试 start */
    // setTimeout(() => {
    //   this.goback(false);
    // }, 3000);
    /** 测试 end */
  },

  mounted() {
    let params = this.$route.params;
    this.type = params.type;
    this.name = params.name;
    this.childName = params.childName;
    this.price = params.price;
    this.childId = params.childId;

    this.intro = this.vipIntro[params.type];

    this.getOrder();

    //更新会员状态
    this.$bridge.registerhandler("showDone", () => {
      this.goback(true);
    });
  },

  methods: {
    getOrder() {
      const url = "api/v1/bizorder";
      const data = {
        biz_type: this.type,
        biz_id: this.childId,
      };
      this.$http.postJsonRequest(url, data).then(
        (res) => {
          this.order_id = res.order_id;
        },
        () => {}
      );
    },

    goback(flag) {
      this.$utils.setLs("isShowDone", flag);
      this.$router.back();
    },

    pay() {
      const url = "api/v1/makePay";
      const data = {
        type: "wechatpay",
        order_id: this.order_id,
      };
      this.$http.getRequest(url, data).then(
        (res) => {
          const h = this.$createElement;
          this.$msgbox({
            title: "提示",
            message: h("p", null, [
              h("span", null, "支付完成前请不要关闭该窗口"),
              h("br"),
              h("span", null, "若支付遇到问题，请联系行云客服微信："),
              h("i", { style: "color: #ff0000" }, "zhixingwrite"),
            ]),
            center: true,
            showClose: false,
            showCancelButton: true,
            closeOnClickModal: false,
            confirmButtonText: "已完成支付",
            cancelButtonText: "取消支付",
            beforeClose: (action, instance, done) => {
              if (action === "confirm") {
                this.$utils.setLs("order_id", this.order_id);
                this.goback(true);
              } else {
                this.goback(false);
              }
              done();
            },
          })
            .then(() => {})
            .catch(() => {});

          this.callNative(res);
        },
        () => {}
      );
    },

    async callNative(data) {
      try {
        this.$bridge.callhandler("wxPay", data, () => {});
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$red: #f01f31;
$gold: #a49170;
$blue: #7fbdf6;
$green: #009966;

$redLine: #d2d2d2;
$goldLine: #e4ded7;
$blueLine: #a9cef3;
$greenLine: #d2d2d2;

$redBg: #ffe9e9;
$goldBg: #fef5e6;
$blueBg: #e8f1fa;
$greenBg: #ccffcc;

.pay-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .main {
    flex: 1;
    overflow: auto;

    .info {
      display: flex;
      margin: 20px 10px;
      align-items: center;
      .vip-name {
        width: 80px;
        height: 100px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
      }
      .vip-name.redvip {
        color: $red;
        border: 1px solid $red;
      }
      .vip-name.goldvip {
        color: $gold;
        border: 1px solid $gold;
      }
      .vip-name.bluevip {
        color: $blue;
        border: 1px solid $blue;
      }
      .vip-name.greenvip {
        color: $green;
        border: 1px solid $green;
      }
      .vip-info {
        margin-left: 10px;
        text-align: left;
        line-height: 2;
        .name {
          font-size: 18px;
          font-weight: 700;
        }
        .price {
          span {
            font-size: 16px;
          }
        }
      }

      .vip-info.redvip {
        color: $red;
      }
      .vip-info.goldvip {
        color: $gold;
      }
      .vip-info.bluevip {
        color: $blue;
      }
      .vip-info.greenvip {
        color: $green;
      }
    }

    .divider {
      height: 10px;
      background: #f0f0f0;
    }
    .intro {
      margin: 20px 10px;
      color: #666;
      margin-top: 20px;
      text-align: left;
      line-height: 2;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price {
      margin: 10px;
      font-weight: 700;
      font-size: 16px;
      a {
        font-weight: 400;
        span {
          font-size: 18px;
          font-weight: 700;
          margin-right: 2px;
        }
      }
    }

    .price a.redvip {
      color: $red;
    }
    .price a.goldvip {
      color: $gold;
    }
    .price a.bluevip {
      color: $blue;
    }
    .price a.greenvip {
      color: $green;
    }

    .right {
      margin-left: 10px;
      margin-right: 10px;
      display: flex;

      .btn {
        width: 110px;
        height: 40px;
        margin: 10px 0;
        color: #fff;
        font-size: 16px;
        line-height: 40px;
        border-radius: 40px;
      }

      .btn.redvip {
        background: $red;
      }
      .btn.goldvip {
        background: $gold;
      }
      .btn.bluevip {
        background: $blue;
      }
      .btn.greenvip {
        background: $green;
      }

      .btn.back {
        width: 90px;
        background: #fff;
        margin-right: 10px;
      }
      .btn.back.redvip {
        color: $red;
        border: 1px solid $red;
      }
      .btn.back.goldvip {
        color: $gold;
        border: 1px solid $gold;
      }
      .btn.back.bluevip {
        color: $blue;
        border: 1px solid $blue;
      }
      .btn.back.greenvip {
        color: $green;
        border: 1px solid $green;
      }
    }
  }
}
</style>
